import React from 'react';
import { Link, useNavigate } from "react-router-dom";

const Dashboard = () => {
    const navigate = useNavigate();
    return (
        <div className="row">
                    <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="dash-widget-header">
                                    <span className="dash-widget-icon bg-1">
                                        <i className="fas fa-dollar-sign"></i>
                                    </span>
                                    <div className="dash-count">
                                        <div className="dash-title">Amount Due</div>
                                        <div className="dash-counts">
                                            <p>0</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="progress progress-sm mt-3">
                                    <div className="progress-bar bg-5 w-100" role="progressbar"
                                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="dash-widget-header">
                                    <span className="dash-widget-icon bg-2">
                                        <i className="fas fa-users"></i>
                                    </span>
                                    <div className="dash-count">
                                        <div className="dash-title">Customers</div>
                                        <div className="dash-counts">
                                            <p>0</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="progress progress-sm mt-3">
                                    <div className="progress-bar bg-6 w-100" role="progressbar"
                                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="dash-widget-header">
                                    <span className="dash-widget-icon bg-3">
                                        <i className="fas fa-file-alt"></i>
                                    </span>
                                    <div className="dash-count">
                                        <div className="dash-title">Invoices</div>
                                        <div className="dash-counts">
                                            <p>0</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="progress progress-sm mt-3">
                                    <div className="progress-bar bg-7 w-100" role="progressbar"
                                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="dash-widget-header">
                                    <span className="dash-widget-icon bg-4">
                                        <i className="far fa-file"></i>
                                    </span>
                                    <div className="dash-count">
                                        <div className="dash-title">Estimates</div>
                                        <div className="dash-counts">
                                            <p>0</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="progress progress-sm mt-3">
                                    <div className="progress-bar bg-8 w-100" role="progressbar"
                                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )
}

export default Dashboard;