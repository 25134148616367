import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import LoginComponent from '../pages/login';
import AdminLayout from '../layout/AdminLayout';
import AdminRoutes from './AdminRoutes';

const Router = () => {
    
    return (
        <Routes>
            <Route path="/" element={<LoginComponent />}></Route>
            <Route path="/login" element={<LoginComponent />} />
            <Route path="admin" element={<Outlet />} >
                {
                    AdminRoutes.map((element) => {
                        return (
                            <Route key={element} path={element.path} element={<AdminLayout component={element.component} />} />
                        );
                    })
                }
            </Route>
            <Route exact path="*" element={<h1>Not Found</h1>} />
        </Routes>
    );
}

export default React.memo(Router);