import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../shared/constant";
import { toast } from "react-toastify";

const ViewPurchase = ({ id }) => {

    const myLogo = sessionStorage.getItem('logo');
    const [amountInWord, setAmountInWord] = useState("");
    const [invoiceType, setInvoiceType] = useState("Original");
    const [extraItems, setExtraItems] = useState(10);
    const [loginUser, setLoginUser] = useState({
        "id": null,
        "name": null,
        "email": null,
        "email_verified_at": null,
        "address_one": null,
        "address_two": null,
        "city": null,
        "pincode": null,
        "gst_no": null,
        "pan_no": null,
        "state_id": null,
        "created_at": null,
        "updated_at": null,
        "state": {
            "id": null,
            "code": null,
            "name": null,
        }
    });
    const [invoice, setInvoice] = useState({
        party: {},
        ship: {},
        bank: {},
        items: []
    });

    const getUserInfo = () => {
        axios.post(API_URL.LOGIN_USER, {}).then((res) => {
            setLoginUser(res.data);
        });

    }
    const getInvoice = () => {
        axios.post(API_URL.PURCHASE_GET, { id: id }).then((res) => {
            if (res.data.status === 1) {
                setInvoice(res.data.data);
                setAmountInWord(inWords(res.data.data.total_amount));
                setExtraItems(10 - res.data.data.items.length);
            } else {
                toast.error(res.data.data);
            }
        });
    }

    const printInvoice = (invType) => {
        setInvoiceType(invType);
        setTimeout(() => {
            window.print();
        }, 300);

    }

    const a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    const inWords = (num) => {
        num = parseInt(num);
        if ((num = num.toString()).length > 9) return 'overflow';
        let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!n) return; var str = '';
        str += (n[1] != '0' && n[1] != '00') ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
        str += (n[2] != '0' && n[2] != '00') ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
        str += (n[3] != '0' && n[3] != '00') ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
        str += (n[4] != '0' && n[4] != '00') ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
        str += (n[5] != '0' && n[5] != '00') ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
        return str;
    }

    useEffect(() => {
        getInvoice();
    }, [id])

    useEffect(() => {
        getUserInfo();
    }, [])

    return (
        <div id="view-invoice" className="view-invoice" style={{ maxWidth: '100%' }}>
            <div className="download-invoice">
                <section className="invoice-cn section-cn" style={{ backgroundColor: "#fff" }}>
                    <div className="row-col">
                        <div className="ouraddress" style={{ width: "100%", clear: "both", margin: 0 }}>
                            <div className="col-4 logo-cn">
                                <div style={{ padding: "20px" }}>
                                    <img
                                        style={{ maxHeight: "115px", maxEidth: "100%" }}
                                        src={myLogo} /></div>
                            </div>
                            <div style={{ padding: "0px" }} className="col-8">
                                <div style={{ padding: "10px 15px 0" }}>
                                    <h2 style={{ paddingTop: "0px", marginTop: 0, textTransform: "uppercase", marginBottom: "4px" }}>
                                        {loginUser.name}
                                    </h2>
                                    <div style={{ display: "flex", paddingBottom: "5px" }}>
                                        <div
                                            style={{ width: "40%", height: "4px", background: "#333333" }}></div>
                                        <div
                                            style={{ width: "40%", height: "4px", background: "#d11025" }}></div>
                                    </div>
                                    <div className="myaddress">{loginUser.address_one}, {loginUser.address_two}</div>
                                    <div className="mycity">{loginUser.city}, {loginUser.state.name}, {loginUser.pincode}</div>
                                </div>
                                <div className="terms">
                                    <div style={{ padding: "0px 15px" }}>
                                        <div className="col-6 f14" style={{ padding: 0 }}>
                                            <div style={{ padding: "2px 0" }}>
                                                <strong style={{ texTransform: "uppercase" }}>GST No : {loginUser.gst_no}</strong>
                                            </div>
                                        </div>
                                        <div className="col-6 f14" style={{ padding: 0 }}>
                                            <div style={{ padding: "2px 0", textAlign: "right" }}>
                                                <strong style={{ textTransform: "uppercase" }}>PAN No: <span className="mypan">{loginUser.pan_no}</span></strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-main">
                            <div className="terms f14">
                                <div className="total-amt" style={{ "padding": "5px 15px", "backgroundColor": "#545558", "color": "#fff" }}>
                                    <span className="mymemo" style={{ "textAlign": "left", "display": "inline-block", "width": "33%" }}>{invoice.invoice_memo}</span>
                                    <span style={{ "fontWeight": "bold", "display": "inline-block", "width": "33%", "textAlign": "center" }}>TAX INVOICE</span>
                                    <span className="mycopy" style={{ "display": "inline-block", "width": "32%", "textAlign": "right" }}>{invoiceType}</span>
                                </div>
                            </div>
                            <div className="invaddress">
                                <div className="col-8"
                                    style={{ "borderTop": "1px solid #000000", "width": "60%", "padding": "0" }}>
                                    <div className="down-height-auto f13" style={{ "padding": "5px 15px", "borderRight": "1px solid #000000", height: "135px" }}>
                                        <table style={{ "width": "100%" }}>
                                            <thead>
                                                <tr>
                                                    <td colSpan="3" className="f12">
                                                        <div
                                                            style={{ "textTransform": "uppercase", "textAlign": "left" }}>Detail of
                                                            Seller</div>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ "width": "70px", "verticalAlign": "top", "position": "relative" }}>
                                                        Name<span style={{ "position": "absolute", "right": "0" }}>:</span>
                                                    </td>
                                                    <td colSpan="2" style={{ "paddingLeft": "5px" }}>
                                                        <h6 className="party_name f14" style={{ "margin": "3px 0px", "fontWeight": "bold", "textAlign": "left" }}>
                                                            {invoice.party.name}
                                                        </h6>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{ "width": "70px", "verticalAlign": "top", "position": "relative" }}>
                                                        Address<span
                                                            style={{ "position": "absolute", "right": "0" }}>:</span></td>
                                                    <td colSpan="2"
                                                        style={{ "paddingLeft": "5px" }}><span
                                                            className="party_address1">{invoice.party.address_one} {invoice.party.address_two}, {invoice.party.pincode}</span></td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{ "width": "70px", "verticalAlign": "top", "position": "relative" }}>
                                                        City<span
                                                            style={{ "position": "absolute", "right": "0" }}>:</span></td>
                                                    <td colSpan="2"
                                                        style={{ "paddingLeft": "5px" }}><span
                                                            className="party_city">{invoice.party.city}</span></td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{ "width": "70px", "verticalAlign": "top", "position": "relative" }}>
                                                        State<span
                                                            style={{ "position": "absolute", "right": "0" }}>:</span></td>
                                                    <td style={{ "paddingLeft": "5px" }}><span
                                                        className="party_state">{invoice.party.state_name}</span>
                                                    </td>
                                                    <td style={{ "width": "150px" }}><span
                                                    >State Code :</span>&nbsp;&nbsp;&nbsp;<span
                                                        className="party_state_code">{invoice.party.state_code}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="down-gst f12"
                                        style={{ "borderTop": "1px solid #000000", "padding": "3px 15px", "borderRight": "1px inset #000000" }}>
                                        <span style={{ "width": "70px", "display": "inline-block" }}>GST
                                            No</span> :&nbsp;&nbsp;<span
                                                className="party_gst">{invoice.party.gst_no}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span

                                                    style={{ "textAlign": "right", "display": "inline-block", "float": "right" }}>PAN NO :
                                            <span className="party_pan">{invoice.party.pan_no}</span></span></div>
                                            <div
                                        style={{ "padding": "5px 15px", "borderTop": "1px solid #000000", "borderRight": "1px solid #000000" }}>
                                        <div style={{ "padding": "2px 0" }}><span
                                            style={{ "display": "inline-block", "width": "120px" }}>Invoice
                                            No</span>&nbsp;:&nbsp;&nbsp;&nbsp;<span
                                                className="bill_invno">{invoice.invoice_no}</span></div>
                                        <div style={{ "padding": "2px 0" }}><span
                                            style={{ "display": "inline-block", "width": "120px" }}>Invoice
                                            Date</span>&nbsp;:&nbsp;&nbsp;&nbsp;<span
                                                className="bill_invdate">{invoice.date}</span></div>
                                        <div style={{ "padding": "2px 0" }}><span
                                            style={{ "display": "inline-block" }}>Tax is payable on reverse
                                            charge</span>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; {(invoice.is_payable) ? 'Yes' : 'No'}</div>
                                    </div>
                                </div>
                                <div className="col-4 f14"
                                    style={{ "borderTop": "1px solid #000000", "width": "40%", "padding": "0", "maxWidth": "40%" }}>
                                    <div style={{ "padding": "5px 15px","borderBottom": "1px solid #000000", }}>
                                        <table style={{ "width": "100%" }}>
                                            <thead>
                                                <tr>
                                                    <td style={{ "width": "100px", "position": "relative" }}>
                                                        State<span
                                                            style={{ "position": "absolute", "right": "0" }}>:</span></td>
                                                    <td style={{ "paddingLeft": "10px" }}>{loginUser.state.name}</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ "width": "100px", "position": "relative" }}>
                                                        State Code<span
                                                            style={{ "position": "absolute", "right": "0" }}>:</span></td>
                                                    <td style={{ "paddingLeft": "10px" }}>{loginUser.state.code}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{ "padding": "5px 15px", "minHeight": "125px" }}>
                                        <table style={{ "width": "100%" }}>
                                            <thead>
                                                <tr>
                                                    <td
                                                        style={{ "width": "100px", "verticalAlign": "top", "position": "relative" }}>
                                                        Transport<span
                                                            style={{ "position": "absolute", "right": "0" }}>:</span></td>
                                                    <td style={{ "paddingLeft": "10px" }}><span
                                                        className="bill_transport"
                                                        style={{ "textTransform": "capitalize" }}>{invoice.transport}</span></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ "position": "relative" }}>Vehicle No<span style={{ "position": "absolute", "right": "0" }}>:</span></td>
                                                    <td style={{ "paddingLeft": "10px" }}><span className="bill_vehicle_no">{invoice.vehicle_no}</span></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ "position": "relative" }}>L.R. No<span style={{ "position": "absolute", "right": "0" }}>:</span></td>
                                                    <td style={{ "paddingLeft": "10px" }}><span className="lr_no">{invoice.lr_no}</span></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ "position": "relative" }}>L.R. Date<span style={{ "position": "absolute", "right": "0" }}>:</span></td>
                                                    <td style={{ "paddingLeft": "10px" }}><span className="lr_date">{invoice.lr_date}</span></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ "position": "relative" }}>Destination<span style={{ "position": "absolute", "right": "0" }}>:</span></td>
                                                    <td style={{ "paddingLeft": "10px" }}><span className="lr_date">{invoice.destination_place}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="particlulars">
                                <div style={{ padding: "0px", fontSize: '13px' }}>
                                    <table style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th
                                                    style={{ "padding": "3px 0px", "width": "35px", "textAlign": "center", "borderBottom": "1px solid #000000", "borderTop": "1px solid #000000" }}>
                                                    Sr.</th>
                                                <th
                                                    style={{ "padding": "3px 0px", "borderBottom": "1px solid #000000", "borderTop": "1px solid #000000", "textAlign": "center" }}>
                                                    Product</th>
                                                <th
                                                    style={{ "padding": "3px 0px", "width": "75px", "borderBottom": "1px solid #000000", "borderTop": "1px solid #000000", "textAlign": "center" }}>
                                                    Size</th>
                                                <th
                                                    style={{ "padding": "3px 0px", "width": "90px", "borderBottom": "1px solid #000000", "borderTop": "1px solid #000000", "textAlign": "center" }}>
                                                    HSN Code</th>
                                                <th
                                                    style={{ "padding": "3px 0px", "width": "70px", "borderBottom": "1px solid #000000", "borderTop": "1px solid #000000", "textAlign": "center" }}>
                                                    Grade</th>
                                                <th
                                                    style={{ "padding": "3px 0px", "width": "50px", "borderBottom": "1px solid #000000", "borderTop": "1px solid #000000", "textAlign": "center" }}>
                                                    Qty</th>
                                                <th
                                                    style={{ "padding": "3px 0px", "width": "50px", "borderBottom": "1px solid #000000", "borderTop": "1px solid #000000", "textAlign": "center" }}>
                                                    Unit</th>
                                                <th
                                                    style={{ "padding": "3px 0px", "width": "60px", "borderBottom": "1px solid #000000", "borderTop": "1px solid #000000", "textAlign": "center" }}>
                                                    Rate</th>
                                                <th
                                                    style={{ "padding": "3px 0px", "width": "50px", "borderBottom": "1px solid #000000", "borderTop": "1px solid #000000", "textAlign": "center" }}>
                                                    GST</th>
                                                <th
                                                    style={{ "padding": "3px 0px", "width": "80px", "borderRight": "none", "borderBottom": "1px solid #000000", "borderTop": "1px solid #000000", "textAlign": "center" }}>
                                                    Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                invoice.items.map((item, key) => {
                                                    return <tr key={key}>
                                                        <td className="no"
                                                            style={{ "padding": "4px 0 0 0px", "textAlign": "center" }}>{(key + 1)}</td>
                                                        <td
                                                            style={{ "padding": "4px 0 0 5px", "textAlign": "left", "fontSize": "12px" }}>{item.product.name}</td>
                                                        <td
                                                            style={{ "padding": "4px 0 0 5px", "textAlign": "center", "fontSize": "12px" }}>{item.product.size}
                                                        </td>
                                                        <td
                                                            style={{ "padding": "4px 0 0 5px", "textAlign": "center", "fontSize": "12px" }}>{item.product.hsn_code}
                                                        </td>
                                                        <td
                                                            style={{ "padding": "4px 0 0 5px", "textAlign": "center", "fontSize": "12px" }}>{item.grade.name}
                                                        </td>
                                                        <td
                                                            style={{ "padding": "4px 0 0 0px", "textAlign": "center", "fontSize": "12px" }}>{item.qty}
                                                        </td>
                                                        <td
                                                            style={{ "padding": "4px 0 0 0px", "textAlign": "center", "fontSize": "12px" }}>{item.product.unit}
                                                        </td>
                                                        <td
                                                            style={{ "padding": "4px 0 0 0px", "textAlign": "center", "fontSize": "12px" }}>{item.rate}
                                                        </td>
                                                        <td
                                                            style={{ "padding": "4px 0 0 0px", "textAlign": "center", "fontSize": "12px" }}>{item.product.gst}%</td>
                                                        <td
                                                            style={{ "padding": "4px 0 0 0px", "textAlign": "center", "fontSize": "12px" }}>{item.amount}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            {
                                                [...Array(extraItems)].map((item, k) => {
                                                    return <tr key={k}>
                                                        <td className="no"
                                                            style={{ "padding": "4px 0 4px 0px", "textAlign": "center" }}>&nbsp;
                                                        </td>
                                                        <td
                                                            style={{ "padding": "4px 0 4px 5px", "textAlign": "left" }}>&nbsp;</td>
                                                        <td
                                                            style={{ "padding": "4px 0 4px 5px", "textAlign": "left" }}>&nbsp;</td>
                                                        <td
                                                            style={{ "padding": "4px 0 4px 5px", "textAlign": "left" }}>&nbsp;</td>
                                                        <td
                                                            style={{ "padding": "4px 0 4px 5px", "textAlign": "left" }}>&nbsp;</td>
                                                        <td
                                                            style={{ "padding": "4px 0 4px 5px", "textAlign": "left" }}>&nbsp;</td>
                                                        <td
                                                            style={{ "padding": "4px 0 4px 0px", "textAlign": "center" }}>&nbsp;
                                                        </td>
                                                        <td
                                                            style={{ "padding": "4px 0 4px 0px", "textAlign": "center" }}>&nbsp;
                                                        </td>
                                                        <td
                                                            style={{ "padding": "4px 0 4px 0px", "textAlign": "center" }}>&nbsp;
                                                        </td>
                                                        <td
                                                            style={{ "padding": "4px 0 4px 0px", "textAlign": "center" }}>&nbsp;
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            <tr>
                                                <td colSpan="5" style={{ "textAlign": "left", "fontSize": "14px", "borderTop": "1px solid" }}></td>
                                                <td style={{ "textAlign": "center", "borderTop": "1px solid", "fontWeight": "bold" }}>{invoice.total_qty}</td>
                                                <td style={{ "borderTop": "1px solid", "textAlign": "center" }}></td>
                                                <td style={{ "fontWeight": "bold", "textAlign": "center", "borderTop": "1px solid" }}></td>
                                                <td style={{ "borderTop": "1px solid", "textAlign": "center" }}></td>
                                                <td style={{ "fontWeight": "bold", "textAlign": "center", "borderTop": "1px solid" }}>{invoice.sub_total}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="payments">
                                <div className="col-8" style={{ "borderTop": "1px solid", "padding": "0", "width": "60%", "fontSize": "13px" }}>
                                    <div className="down-height p-2">
                                        Remark: {invoice.remark}
                                    </div>
                                    <div className="grey f12"
                                        style={{ "padding": "2px 5px 0px 15px", "backgroundColor": "#ccc", "color": "#000000", "textAlign": "left", "minHeight": "60px" }}>
                                        <div><strong>Rupees In Word</strong>
                                        </div>
                                        <div className="amount_in_word">{amountInWord}</div>
                                    </div>
                                </div>
                                <div className="col-4 f14" style={{ "borderTop": "1px solid", "padding": "0", "width": "40%", "maxWidth": "40%" }}>
                                    <div className="down-height" style={{ "borderLeft": "1px solid", "textAlign": "right", "padding": "5px 10px 5px 15px", "position": "relative" }}>
                                        {(invoice.insurance > 0) && <div style={{ "padding": "1px 0" }}>
                                            <span className="ggst_span" style={{ "textAlign": "left", "display": "inline-block" }}>Insurance ({invoice.insurance}%)</span>&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="ggst_amount" style={{ "textAlign": "right", "display": "inline-block", "minWidth": "85px" }}>{invoice.insurance_amount}&nbsp;&nbsp;</span>
                                        </div>}
                                        <div style={{ "padding": "1px 0" }}>
                                            <span className="ggst_span" style={{ "textAlign": "left", "display": "inline-block" }}>GST</span>&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="ggst_amount" style={{ "textAlign": "right", "display": "inline-block", "minWidth": "85px" }}>{invoice.tax_amount}&nbsp;&nbsp;</span>
                                        </div>
                                        <div style={{ "padding": "1px 0" }}>
                                            <span className="ggst_span" style={{ "textAlign": "left", "display": "inline-block" }}>Round Off</span>&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="ggst_amount" style={{ "textAlign": "right", "display": "inline-block", "minWidth": "85px" }}>{invoice.round_off}&nbsp;&nbsp;</span>
                                        </div>
                                    </div>
                                    <div className="grey"
                                        style={{ "padding": "8px 10px 8px 15px", "backgroundColor": "#ccc", "textAlign": "right", "color": "#000000", "minHeight": "60px" }}>
                                        <div style={{ "padding": "0", "fontWeight": "800" }}><span
                                            className="cgst_span"
                                            style={{ "textAlign": "left", "display": "inline-block" }}>GRAND
                                            TOTAL</span>:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                                                className="cgst_amount"
                                                style={{ "textAlign": "right", "display": "inline-block", "minWidth": "85px", "paddingRight": "10px" }}>{invoice.total_amount}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ViewPurchase;