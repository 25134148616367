import React from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";

const Sidebar = () => {
    const location = useLocation();
    const myLogo = sessionStorage.getItem('logo');
    return (
        <div className="sidebar hidden-print" id="sidebar">
            <div className="sidebar-header">
                <div className="sidebar-logo">
                    <Link to="/">
                        <img src={myLogo} className="img-fluid logo" alt="1" />
                    </Link>
                    <Link to="/">
                        <img src="/assets/img/logo-small.png" className="img-fluid logo-small" alt="2" />
                    </Link>
                </div>
            </div>
            <div className="sidebar-inner slimscroll">
                <div id="sidebar-menu" className="sidebar-menu">

                    <ul>
                        <li className={ (location.pathname.includes('/admin/dashboard')) ? 'active': '' }>
                            <Link to="/admin/dashboard"><i className="fe fe-home"></i> <span>Dashboard</span></Link>
                        </li>
                        <li className="menu-title"><span>Invoices</span></li>
                        <li className={ (location.pathname.includes('/admin/invoice')) ? 'active': '' }>
                            <Link to="/admin/invoice"><i className="fe fe-file"></i> <span>Sales</span></Link>
                        </li>
                        <li className={ (location.pathname.includes('/admin/sales-return')) ? 'active': '' }>
                            <Link to="/admin/sales-return"><i className="fe fe-edit"></i> <span>Sales Return</span></Link>
                        </li>
                        <li className={ (location.pathname.includes('/admin/purchases')) ? 'active': '' }>
                            <Link to="/admin/purchases"><i className="fe fe-file-text"></i> <span>Purchase</span></Link>
                        </li>
                        <li className={ (location.pathname.includes('/admin/purchase-return')) ? 'active': '' }>
                            <Link to="/admin/purchase-return"><i className="fe fe-edit"></i> <span>Purchase Return</span></Link>
                        </li>
                        {/* <li className={ (location.pathname.includes('/admin/perfoma')) ? 'active': '' }>
                            <Link to="/admin/perfoma"><i className="fe fe-edit"></i> <span>Perfoma</span></Link>
                        </li> */}
                        <li className="menu-title"><span>Main</span></li>
                        <li className={ (location.pathname.includes('admin/party')) ? 'active': '' }>
                            <Link to="/admin/party"><i className="fe fe-users"></i> <span>Party</span></Link>
                        </li>
                        <li className={ (location.pathname.includes('/admin/product')) ? 'active': '' }>
                            <Link to="/admin/product"><i className="fe fe-package"></i> <span>Product</span></Link>
                        </li>
                        {/* <li className={ (location.pathname.includes('/admin/category')) ? 'active': '' }>
                            <Link to="/admin/category"><i className="fe fe-package"></i> <span>Category</span></Link>
                        </li> */}
                        <li className={ (location.pathname.includes('/admin/transport')) ? 'active': '' }>
                            <Link to="/admin/transport"><i className="fe fe-edit"></i> <span>Transport</span></Link>
                        </li>
                        <li className={ (location.pathname.includes('/admin/grade')) ? 'active': '' }>
                            <Link to="/admin/grade"><i className="fe fe-package"></i> <span>Grade</span></Link>
                        </li>
                        <li className={ (location.pathname.includes('/admin/banks')) ? 'active': '' }>
                            <Link to="/admin/banks"><i className="fe fe-codepen"></i> <span>Banks</span></Link>
                        </li>
                        {/* <li className={ (location.pathname.includes('/admin/profile')) ? 'active': '' }>
                            <Link to="/admin/profile"><i className="fe fe-settings"></i> <span>Settings</span></Link>
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;