import './App.css';
import Router from "./router/Router";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

function App() {
  return (
    <Router />
  );
}

export default App;
