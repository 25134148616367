
import Dashboard from '../pages/dashboard';
import Category from '../pages/category';
import AddCategory from '../pages/category/add';
import Grade from '../pages/grade';
import AddGrade from '../pages/grade/add';
import Party from '../pages/party';
import AddParty from '../pages/party/add';
import Product from '../pages/product';
import AddProduct from '../pages/product/add';
import Purchase from '../pages/purchase';
import AddPurchase from '../pages/purchase/add';
import DebitNotes from '../pages/notes/debitNote';
import AddInvoice from '../pages/sales/add';
import AddInvoices from '../pages/invoice/add';
import Invoices from '../pages/invoice';
import AddDebitNote from '../pages/notes/addDebit';
import Perfoma from '../pages/sales/perfoma';
import AddPerfoma from '../pages/sales/addPerfoma';
import Banks from '../pages/banks';
import AddBanks from '../pages/banks/add';
import Profile from '../pages/settings/profile';
import Pdf from '../shared/pdf';
import AddCredit from '../pages/notes/addCredit';
import CreditNotes from '../pages/notes/creditNote';
import Transport from '../pages/transport';
import AddTransport from '../pages/transport/add';


const AdminRoutes = [
    { path: '', component: <Dashboard /> },
    { path: 'dashboard', component: <Dashboard /> },
    { path: 'category', component: <Category /> },
    { path: 'category/add', component: <AddCategory /> },
    { path: 'category/edit/:id', component: <AddCategory /> },
    { path: 'party', component: <Party /> },
    { path: 'party/add', component: <AddParty /> },
    { path: 'party/edit/:id', component: <AddParty /> },
    { path: 'product', component: <Product /> },
    { path: 'product/add', component: <AddProduct /> },
    { path: 'product/edit/:id', component: <AddProduct /> },
    { path: 'grade', component: <Grade /> },
    { path: 'grade/add', component: <AddGrade /> },
    { path: 'grade/edit/:id', component: <AddGrade /> },
    { path: 'transport', component: <Transport /> },
    { path: 'transport/add', component: <AddTransport /> },
    { path: 'transport/edit/:id', component: <AddTransport /> },
    { path: 'banks', component: <Banks /> },
    { path: 'banks/add', component: <AddBanks /> },
    { path: 'banks/edit/:id', component: <AddBanks /> },
    { path: 'invoice', component: <Invoices /> },
    { path: 'invoice/add', component: <AddInvoices /> },
    { path: 'invoice/edit/:id', component: <AddInvoices /> },
    { path: 'invoice/view/:id', component: <Pdf /> },
    { path: 'purchase-return', component: <DebitNotes /> },
    { path: 'purchases', component: <Purchase /> },
    { path: 'perfoma', component: <Perfoma /> },
    { path: 'add-perfoma', component: <AddPerfoma /> },
    { path: 'add-invoice', component: <AddInvoice /> },
    { path: 'purchase-return/add', component: <AddDebitNote /> },
    { path: 'purchase-return/edit/:id', component: <AddDebitNote /> },
    { path: 'sales-return', component: <CreditNotes /> },
    { path: 'sales-return/add', component: <AddCredit /> },
    { path: 'sales-return/edit/:id', component: <AddCredit /> },
    { path: 'purchases/add', component: <AddPurchase /> },
    { path: 'purchases/edit/:id', component: <AddPurchase /> },
    { path: 'profile', component: <Profile /> }
];

export default AdminRoutes;