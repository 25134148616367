import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../shared/constant";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import DatePicker from "react-date-picker";
import moment from 'moment';
import { Oval } from "react-loader-spinner";

const AddInvoice = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const selectInputRef = useRef();
    const selectGradeInputRef = useRef();
    const [invoiceDate, setInvoiceDate] = useState();
    const [lrDate, setLrDate] = useState();
    const [partyData, setPartyData] = useState([]);
    const [products, setProducts] = useState([]);
    const [grades, setGrades] = useState([]);
    const [transports, setTransports] = useState([]);
    const [statesData, setStatesData] = useState([]);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [transportId, setTransportId] = useState("");
    const [productData, setProductData] = useState({
        name: "",
        product_id: "",
        grade_name: "",
        grade_id: "",
        gst: "",
        qty: "",
        rate: "",
        amount: "",
    });
    const [seller, setSeller] = useState({
        address_one: "",
        address_two: "",
        city: "",
        gst_no: "",
        pan_no: "",
        state_id: "",
        state_name: "",
        pincode: "",
        name: ""
    });
    const [formData, setFormData] = useState({
        "party_id": "",
        "invoice_no": "",
        "insurance": "",
        "insurance_amount": "0",
        "is_igst": "",
        "invoice_memo": "",
        "is_payable": "0",
        "round_off": "0",
        "lr_no": "",
        "lr_date": "",
        "transport": "",
        "vehicle_no": "",
        "transport_gst": "",
        "destination_place": "",
        "sub_total": "0",
        "tax_amount": "0",
        "total_amount": "0",
        "date": "",
        "ship_name": "",
        "ship_address_one": "",
        "ship_address_two": "",
        "ship_city": "",
        "ship_state_id": "",
        "ship_pincode": "",
        "ship_gst_no": "",
        "ship_pan_no": "",
        "items": []
    });
    const styles = {
        menu: (baseStyles, state) => ({
            ...baseStyles,
            marginTop: 2,
            zIndex: 999,
        }),
    };

    const memoOptions = [
        { value: "Debit Memo", label: "Debit Memo" },
        { value: "Cash Memo", label: "Cash Memo" },
    ];

    const taxOptions = [
        { value: "1", label: "Yes" },
        { value: "0", label: "No" },
    ];

    const getParty = () => {
        axios.post(API_URL.PARTY_LIST, { is_seller: [1] }).then((res) => {
            if (res.data && res.data.data && res.data.data.length > 0) {
                let pt = res.data.data.map((raw) => {
                    return {
                        address_one: (raw.address_one) ? raw.address_one: "",
                        address_two: (raw.address_two) ? raw.address_two: "",
                        pincode: (raw.pincode) ? raw.pincode: "",
                        city: (raw.city) ? raw.city: "",
                        gst_no: (raw.gst_no) ? raw.gst_no: "",
                        pan_no: (raw.pan_no) ? raw.pan_no: "",
                        state_name: (raw.state_name) ? raw.state_name: "",
                        state_id: raw.state_id,
                        name: raw.name,
                        label: raw.name,
                        value: raw.id,
                    };
                });
                setPartyData(pt);
            }
        });
    }

    const getStates = () => {
      axios.post(API_URL.STATES_LIST, {}).then((res) => {
        if (res.data.status === 1) {
          let stsData = res.data.data.map((row) => {
            return { label: row.name, value: row.id };
          });
          setStatesData(stsData);
        }
      });
    };

    const getEdit = () => {
        axios.post(API_URL.INVOICE_GET, {id: id}).then((res) => {
            if (res.data.status == 1) {
                setFormData({
                    "party_id": (res.data.data.party_id) ? res.data.data.party_id : "",
                    "invoice_no": (res.data.data.invoice_no) ? res.data.data.invoice_no : "",
                    "insurance": (res.data.data.insurance) ? res.data.data.insurance : "",
                    "insurance_amount": (res.data.data.insurance_amount) ? res.data.data.insurance_amount : "0",
                    "is_igst": (res.data.data.is_igst) ? res.data.data.is_igst : "",
                    "invoice_memo": (res.data.data.invoice_memo) ? res.data.data.invoice_memo : "",
                    "is_payable": (res.data.data.is_payable) ? res.data.data.is_payable : "0",
                    "round_off": (res.data.data.round_off) ? res.data.data.round_off : "0",
                    "lr_no": (res.data.data.lr_no) ? res.data.data.lr_no : "",
                    "lr_date": (res.data.data.lr_date) ? res.data.data.lr_date : "",
                    "transport": (res.data.data.transport) ? res.data.data.transport : "",
                    "vehicle_no": (res.data.data.vehicle_no) ? res.data.data.vehicle_no : "",
                    "transport_gst": (res.data.data.transport_gst) ? res.data.data.transport_gst : "",
                    "destination_place": (res.data.data.destination_place) ? res.data.data.destination_place : "",
                    "sub_total": res.data.data.sub_total,
                    "tax_amount": res.data.data.tax_amount,
                    "total_amount": res.data.data.total_amount,
                    "date": (res.data.data.date) ? res.data.data.date : "",
                    "remark": (res.data.data.remark) ? res.data.data.remark : "",
                    "ship_name": (res.data.data.ship_name) ? res.data.data.ship_name : "",
                    "ship_address_one": (res.data.data.ship_address_one) ? res.data.data.ship_address_one : "",
                    "ship_address_two": (res.data.data.ship_address_two) ? res.data.data.ship_address_two : "",
                    "ship_city": (res.data.data.ship_city) ? res.data.data.ship_city : "",
                    "ship_state_id": (res.data.data.ship_state_id) ? res.data.data.ship_state_id : "",
                    "ship_pincode": (res.data.data.ship_pincode) ? res.data.data.ship_pincode : "",
                    "ship_gst_no": (res.data.data.ship_gst_no) ? res.data.data.ship_gst_no : "",
                    "ship_pan_no": (res.data.data.ship_pan_no) ? res.data.data.ship_pan_no : "",
                    "items": [],
                });
                if (res.data.data.date) {
                    setInvoiceDate(new Date(res.data.data.date));
                }
                if (res.data.data.lr_date) {
                    setLrDate(new Date(res.data.data.lr_date));
                }
                let rows = res.data.data.items.map((row) => {
                    return {
                        name: row.product.name,
                        gst: row.product.gst,
                        qty: row.qty,
                        rate: row.rate,
                        amount: row.amount,
                        product_id: row.product_id,
                        grade_name: row.grade_id,
                        grade_id: row.grade_id,
                        sub_product_id: row.sub_product_id
                    }
                });
                setItems(rows);
                setSeller({
                    address_one: res.data.data.party.address_one,
                    address_two: res.data.data.party.address_two,
                    city: res.data.data.party.city,
                    state_id: res.data.data.party.state_id,
                    state_name: res.data.data.party.state_name,
                    pincode: res.data.data.party.pincode,
                    gst_no: res.data.data.party.gst_no,
                    pan_no: res.data.data.party.pan_no,
                    name: res.data.data.party.name
                });
            } else {
                toast.error(res.data.data, { autoClose: 3000, position: "top-center" });
            }
            setLoading(false);
        });
    }
    
    const getProducts = () => {
        axios.post(API_URL.PRODUCT_LIST, {}).then((res) => {
            if (res.data && res.data.data && res.data.data.length > 0) {
                res.data.data.map((raw) => {
                    raw.label = raw.size ? raw.name + ' - ' + raw.size  : raw.name;
                    raw.value = raw.id;
                });
                setProducts(res.data.data);
            }
            if (!id) {
              setLoading(false);
            }
        });
    }
    
    const getGrades = () => {
        axios.post(API_URL.GRADE_LIST, {}).then((res) => {
            if (res.data && res.data.data && res.data.data.length > 0) {
                res.data.data.map((raw) => {
                    raw.label = raw.name;
                    raw.value = raw.id;
                });
                setGrades(res.data.data);
            }
            if (!id) {
              setLoading(false);
            }
        });
    }

    const getTransport = () => {
        axios.post(API_URL.TRANSPORT_LIST, { is_seller: [0, 1] }).then(
          (res) => {
            if (res.data.status === 1) {
                res.data.data.forEach((r) => {
                    r.label = r.name;
                    r.id = r.id;
                })
                setTransports(res.data.data);
            }
        });
      };

    const setSelectedParty = (party_id) => {
        const party = partyData.find(r => r.value == party_id);
        if (party) {
            setSeller(party);
            setFormData({...formData,
              party_id: party_id,
              ship_name: party.name,
              ship_address_one: party.address_one,
              ship_address_two: party.address_two,
              ship_city: party.city,
              ship_state_id: party.state_id,
              ship_pincode: party.pincode,
              ship_gst_no: party.gst_no,
              ship_pan_no: party.pan_no
            });
        }
    }

    const setProductValue = (e) => {
        if (e) {
            setProductData({
                ...productData,
                name: e ? e.label: "",
                product_id: e ? e.value: "",
                gst: e ? e.gst: "",
            });               
        }
    }

    const setGradeValue = (e) => {
        if (e) {
            setProductData({
                ...productData,
                grade_name: e ? e.label: "",
                grade_id: e ? e.value: ""
            });               
        }
    }

    const addRow = () => {
        if (productData.product_id && productData.grade_id && productData.qty && productData.rate && productData.amount) {
            let cloneItems = [...items];
            cloneItems.push(productData);
            setItems(cloneItems);
            setProductData({
                name: "",
                product_id: "",
                grade_name: "",
                grade_id: "",
                gst: "",
                qty: "",
                rate: "",
                amount: "",
            });
            selectInputRef.current.clearValue();
            selectGradeInputRef.current.clearValue();
        }
    }
    const updateValue = (name, amt, value, k) => {
        const copyData = [...items]
        if (copyData[k]) {
            copyData[k][name] = value;
            if (name === 'qty')
                copyData[k][amt] = value * copyData[k]['rate']
            if (name === 'rate')
                copyData[k][amt] = value * copyData[k]['qty']

        }
        setItems(copyData);
    }

    const deleteRow = (index) => {
        const copyData = [...items]
        let newData = copyData.filter((e, key) => {
            return key != index;
        });
        setItems(newData)
    }

    const calculations = () => {
        let sub_total = 0;
        let total_qty = 0;
        let tax_amount = 0;
        items.forEach((row) => {
            sub_total += parseFloat(row.amount);
            total_qty += parseFloat(row.qty);
            tax_amount += (parseFloat(row.amount) * parseFloat(row.gst)) / 100;
        });
        let insurance_amount = 0;
        if (formData.insurance) {
            insurance_amount = (sub_total * formData.insurance) / 100;
            tax_amount += (parseFloat(insurance_amount) * 18) / 100;
            insurance_amount = insurance_amount.toFixed(2);
        }
        sub_total = sub_total.toFixed(2);
        let total_amount = parseFloat(sub_total) + parseFloat(insurance_amount);
        if (formData.round_off) {
            total_amount = parseFloat(total_amount) + parseFloat(formData.round_off);
        }
        if (tax_amount) {
            tax_amount = tax_amount.toFixed(2);
            total_amount = parseFloat(total_amount) + parseFloat(tax_amount);
        }
        total_amount = total_amount.toFixed(2);
        setFormData({...formData,
            sub_total: sub_total,
            insurance_amount: insurance_amount,
            total_qty: total_qty,
            tax_amount: tax_amount,
            total_amount: total_amount,
        });
    }

    const isValidForm = () => {
        return (
          formData.party_id &&
          formData.invoice_no &&
          formData.sub_total &&
          formData.total_amount &&
          invoiceDate &&
          items.length
        );
    };
    
    const saveInvoice = () => {
        let payload = {...formData};
        payload.items = [...items];
        payload.date = moment(invoiceDate).format('YYYY-MM-DD');
        if (lrDate) {
            payload.lr_date = moment(lrDate).format('YYYY-MM-DD');
        }
        const pUrl = id ? API_URL.INVOICE_UPDATE : API_URL.INVOICE_SAVE;
        if (id) {
            payload.id = id;
        }
        setIsLoading(true);
        axios.post(pUrl, payload).then((res) => {
            if (res.data.status === 1) {
                if (id) {
                    toast.success("Updated", {
                        autoClose: 3000,
                        position: "top-center",
                    });
                } else {
                    toast.success("Saved", { autoClose: 3000, position: "top-center" });
                }
                setTimeout(() => {
                    navigate("/admin/invoice/view/" + res.data.data.id, { replace: true });
                }, 100);
            } else {
                toast.error(res.data.data, { autoClose: 3000, position: "top-center" });
            }
            setIsLoading(false);
        }, (error) => {
          setIsLoading(false);
        });
    } 

    useEffect(() => {
        calculations();
    }, [items, formData.insurance, formData.round_off])

    useEffect(() => {
        getParty();
        getProducts();
        getStates();
        getGrades();
        getTransport();
    }, [])

    useEffect(() => {
        if (id) {
            getEdit()
        }
    }, [id])
    return (
        <>
            <div className="page-header">
                <div className="content-page-header">
                    <h5>{id ? "Edit" : "Add"} Invoice</h5>
                    <div className="list-btn">
                        <ul className="filter-list">
                            <li>
                                <Link to={"/admin/invoice"} className="btn btn-secondary">
                                    <i className="fa fa-backward me-2" aria-hidden="true"></i>Back
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            { loading ? (
                <Oval
                  height={60}
                  width={60}
                  color="#7539ff"
                  wrapperStyle={{ justifyContent: "center" }}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#7539ff"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : <>
            <form onSubmit={(event) => event.preventDefault()}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mb-3 title-header">Billing Details</h4>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="name">Party</label>
                                            <Select
                                                options={partyData}
                                                value={partyData.find((r) => r.value == formData.party_id)}
                                                styles={styles}
                                                onChange={(e) => {
                                                    setSelectedParty(e.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="address_one">Address Line One</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                disabled
                                                value={seller.address_one}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="address_two">Address Line Two</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                disabled
                                                value={(seller.address_two) ? seller.address_two: ""}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="city">City</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                disabled
                                                value={(seller.city) ? seller.city: ""}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="state">State</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                disabled
                                                value={(seller.state_name) ? seller.state_name: ""}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="state">Pincode</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                disabled
                                                value={(seller.pincode) ? seller.pincode: ""}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="gst_no">GST No</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                disabled
                                                value={(seller.gst_no) ? seller.gst_no: ""}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="gst_no">PAN No</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                disabled
                                                value={(seller.pan_no) ? seller.pan_no: ""}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mb-3 title-header">Shipping Details</h4>
                                <div className="row">
                                    <div className="col-md-12">
                                          <div className="form-group">
                                            <label htmlFor="party_name">Party</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              value={formData.ship_name}
                                              onChange={(e) => setFormData({ ...formData, ship_name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="address_one">Address Line One</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.ship_address_one}
                                                onChange={(e) => setFormData({ ...formData, ship_address_one: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="address_two">Address Line Two</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.ship_address_two}
                                                onChange={(e) => setFormData({ ...formData, ship_address_two: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="city">City</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.ship_city}
                                                onChange={(e) => setFormData({ ...formData, ship_city: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="state">State</label>
                                            <Select
                                              options={statesData}
                                              value={statesData.find(
                                                (r) => r.value == formData.ship_state_id
                                              )}
                                              styles={styles}
                                              onChange={(e) => setFormData({ ...formData, ship_state_id: e.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="state">Pincode</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.ship_pincode}
                                                onChange={(e) => setFormData({ ...formData, ship_pincode: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="gst_no">GST No</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.ship_gst_no}
                                                onChange={(e) => setFormData({ ...formData, ship_gst_no: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="gst_no">PAN No</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.ship_pan_no}
                                                onChange={(e) => setFormData({ ...formData, ship_pan_no: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mb-3 title-header">Invoice Details</h4>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="invoiceno">Invoice No</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.invoice_no}
                                                onChange={(e) => setFormData({ ...formData, invoice_no: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">Invoice Date</label>
                                            <DatePicker
                                                format={'dd/MM/y'}
                                                className="form-control"
                                                onChange={setInvoiceDate}
                                                value={invoiceDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="memo">Memo</label>
                                            <Select
                                                options={memoOptions}
                                                styles={styles}
                                                value={memoOptions.find(
                                                    (r) => r.value == formData.invoice_memo
                                                )}
                                                onChange={(e) =>
                                                    setFormData({ ...formData, invoice_memo: e.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="insurance">Insurance (%)</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={formData.insurance}
                                                onChange={(e) => setFormData({ ...formData, insurance: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="tax">
                                                Tax is Payable on reverse charge?
                                            </label>
                                            <Select
                                                options={taxOptions}
                                                styles={styles}
                                                value={taxOptions.find((r) => r.value == formData.is_payable)}
                                                onChange={(e) =>
                                                    setFormData({ ...formData, is_payable: e.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mb-3 title-header">Transport Details</h4>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="transporter">Transporter</label>
                                            <Select
                                                options={transports}
                                                styles={styles}
                                                value={transports.find((r) => r.value == transportId)}
                                                onChange={(e) => [
                                                    setTransportId(e.value),
                                                    setFormData({ ...formData, transport: e.name, transport_gst: e.gst_no })
                                                    ]
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="vehicleno">Vehicle No</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.vehicle_no}
                                                onChange={(e) => setFormData({ ...formData, vehicle_no: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="lrno">LR No</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.lr_no}
                                                onChange={(e) => setFormData({ ...formData, lr_no: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="lrdate">LR Date</label>
                                            <DatePicker
                                                format={'dd/MM/y'}
                                                className="form-control"
                                                onChange={setLrDate}
                                                value={lrDate}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="destination">Destination</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.destination_place}
                                                onChange={(e) => setFormData({ ...formData, destination_place: e.target.value })}
                                            />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mb-3 title-header">Product Details</h4>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Product</label>
                                            <Select
                                                ref={selectInputRef}
                                                options={products}
                                                styles={styles}
                                                onChange={(e) => { setProductValue(e) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Grade</label>
                                            <Select
                                                ref={selectGradeInputRef}
                                                options={grades}
                                                styles={styles}
                                                onChange={(e) => { setGradeValue(e) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Qty</label>
                                            <input
                                                type="number"
                                                onChange={(e) => {
                                                    setProductData({
                                                        ...productData,
                                                        qty: e.target.value,
                                                        amount: (e.target.value * productData.rate) ? e.target.value * productData.rate : 0,
                                                    });
                                                }}
                                                value={productData.qty}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Price</label>
                                            <input
                                                type="number"
                                                onChange={(e) => {
                                                    setProductData({
                                                        ...productData,
                                                        rate: e.target.value,
                                                        amount: (e.target.value * productData.qty) ? e.target.value * productData.qty : 0,
                                                    });
                                                }}
                                                value={productData.rate}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Amount</label>
                                            <input
                                                type="text"
                                                value={productData.amount}
                                                className="form-control"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className="form-group">
                                            <label>&nbsp;</label>
                                            <div>
                                                <button onClick={() => addRow()} className="btn btn-primary btn-outline"><span className="fa fa-plus f-20"></span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    {items.length > 0 &&
                        <table className='table table-hover'>
                            <thead className='thead'>
                                <tr>
                                    <th>#</th>
                                    <th style={{ width: '45%' }}>Product</th>
                                    <th>Grade</th>
                                    <th>Qty</th>
                                    <th>Price</th>
                                    <th>GST</th>
                                    <th>Amount</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    items.map((e, k) => {
                                        return <tr key={k}>
                                            <td>{k + 1}</td>
                                            <td style={{ width: '45%' }}>{e.name}</td>
                                            <td>{e.grade_name}</td>
                                            <td><input type="number" onChange={(event) => { updateValue('qty', 'amount', event.target.value, k) }} className="form-control form-control-sm" value={e.qty} /></td>
                                            <td><input type="number" onChange={(event) => { updateValue('rate', 'amount', event.target.value, k) }} className="form-control form-control-sm" value={e.rate} /></td>
                                            <td><input type="text" className="form-control form-control-sm" value={e.gst +'%'} disabled /></td>
                                            <td><input type="text" className="form-control form-control-sm" value={e.amount} disabled /></td>
                                            <td style={{ cursor: "pointer" }}>
                                                <button onClick={() => { deleteRow(k) }} className="btn btn-danger btn-outline"><span className="fa fa-close"></span></button>
                                                {/* <AiFillCloseSquare color={'red'} onClick={() => { deleteRow(k) }} size={35} /> */}
                                            </td>
                                        </tr>
                                    })
                                }
                                <tr className="border-top">
                                    <td className="border-end" colSpan={5} align={'right'}></td>
                                    <td className="border-end" align={'right'}><strong>Sub Total</strong></td>
                                    <td colSpan={1} align={'right'}><strong>{formData.sub_total}</strong></td>
                                    <td></td>
                                </tr>
                                { (formData.insurance) ? <tr className="border-top">
                                    <td className="border-end" colSpan={5} align={'right'}></td>
                                    <td className="border-end" align={'right'}><strong>Insurance ({formData.insurance}%)</strong></td>
                                    <td colSpan={1} align={'right'}><strong>{formData.insurance_amount}</strong></td>
                                    <td></td>
                                </tr>: null}
                                <tr className="border-top">
                                    <td className="border-end" colSpan={5} align={'right'}></td>
                                    <td className="border-end" align={'right'}><strong>GST</strong></td>
                                    <td colSpan={1} align={'right'}><strong>{formData.tax_amount}</strong></td>
                                    <td></td>
                                </tr>
                                <tr className="border-top">
                                    <td className="border-end" rowSpan={2} colSpan={5}>
                                        <label>Remark</label>
                                        <input type={'text'} maxLength={255} value={formData.remark} placeholder="Remark" className='form-control' onChange={(e) => setFormData({...formData, remark: e.target.value})} />
                                    </td>
                                    <td className="border-end" align={'right'}><strong>Round Off</strong></td>
                                    <td colSpan={1} align={'right'}> <input type={'number'} value={formData.round_off} className='form-control form-control-sm text-end' onChange={(e) => setFormData({...formData, round_off: e.target.value})} /> </td>
                                    <td></td>
                                </tr>
                                <tr className="border-top">
                                    <td className="border-end" align={'right'}><strong>Final Amount</strong></td>
                                    <td colSpan={1} align={'right'}><strong>{formData.total_amount}</strong></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    }
                </div>
            </form>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group text-end">
                    <button
                        type="button"
                        onClick={saveInvoice}
                        disabled={!isValidForm() || isLoading}
                        className="btn btn-primary" >
                          { isLoading ? <span className="spinner-border spinner-border-sm me-2" role="status"></span> : null}
                        Save Invoice
                    </button>
                </div>
            </div> </>
          }
        </>
    )
}

export default AddInvoice;