import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { API_URL } from "../../shared/constant";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const Grade = () => {
    const [gradeData, setGradeData] = useState([]);
    const [loading, setLoading] = useState(true);
  
    const getGrade = () => {
      axios.post(API_URL.GRADE_LIST, { is_seller: [0, 1] }).then(
        (res) => {
          if (res.data.status === 1) {
            setGradeData(res.data.data);
          }
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          toast.error("Something went wrong..", {
            autoClose: 3000,
            position: "top-center",
          });
        }
      );
    };
  
    const deleteCategory = (row) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this - " + row.name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          axios.post(API_URL.GRADE_DELETE, { id: row.id }).then(
            (res) => {
              if (res.data.status === 1) {
                let newData = gradeData.filter((r) => r.id != row.id);
                setGradeData(newData);
              }
              toast.success(row.name + " Delete Successfully", {
                autoClose: 3000,
                position: "top-center",
              });
              setLoading(false);
            },
            (error) => {
              setLoading(false);
              toast.error("Something went wrong..", {
                autoClose: 3000,
                position: "top-center",
              });
            }
          );
        }
      });
    };
  
    useEffect(() => {
        getGrade();
    }, []);
  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <h5>Grade({gradeData.length})</h5>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <Link to={"/admin/grade/add"} className="btn btn-primary">
                  <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>
                  Add Grade
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card-table">
            <div className="card-body">
              {loading ? (
                <Oval
                  height={60}
                  width={60}
                  color="#7539ff"
                  wrapperStyle={{ justifyContent: "center" }}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#7539ff"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                <div className="table-responsive">
                  <table className="table table-center table-hover datatable">
                    <thead className="thead-light">
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gradeData.map((row, key) => {
                        return (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>
                              <h2 className="table-avatar">{row.name}</h2>
                            </td>
                            <td>
                              <Link
                                to={"/admin/grade/edit/" + row.id}
                                className="btn btn-sm btn-secondary me-2"
                              >
                                <i className="fa fa-edit me-1"></i> Edit
                              </Link>
                              <button
                                onClick={() => deleteCategory(row)}
                                className="btn btn-sm btn-danger me-2"
                              >
                                <i className="fa fa-trash me-1"></i> Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Grade;
