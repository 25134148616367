import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../shared/constant";
import axios from "axios";
import { toast } from "react-toastify";

const AddBanks = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [formdata, setFormData] = useState({
    id: null,
    account_name: "",
    bank_name: "",
    branch_name: "",
    account_number: "",
    bank_code: "",
    is_active: false
  });

  const saveCategory = () => {
    if (
      formdata.bank_name &&
      formdata.branch_name &&
      formdata.account_number &&
      formdata.bank_code &&
      formdata.account_name
    ) {
      const pUrl = id ? API_URL.BANKS_SAVE : API_URL.BANKS_SAVE;
      axios.post(pUrl, formdata).then((res) => {
        if (res.data.status === 1) {
          if (id) {
            toast.success("Updated", {
              autoClose: 3000,
              position: "top-center",
            });
            setTimeout(() => {
              navigate("/admin/banks", { replace: true });
            }, 100);
          } else {
            resetForm();
            toast.success("Saved", { autoClose: 3000, position: "top-center" });
          }
        }
      });
    }
  };

  const resetForm = () => {
    setFormData({
      id: null,
      name: "",
    });
  };

  const onEdit = () => {
    axios.post(API_URL.BANKS_GET, { id: id }).then((res) => {
      if (res.data.status === 1) {
        setFormData({
          id: res.data.data.id,
          is_active: (res.data.data.is_active) ? true : false,
          bank_name: res.data.data.bank_name ? res.data.data.bank_name : "",
          branch_name: res.data.data.branch_name
            ? res.data.data.branch_name
            : "",
          account_number: res.data.data.account_number
            ? res.data.data.account_number
            : "",
          bank_code: res.data.data.bank_code ? res.data.data.bank_code : "",
          account_name: res.data.data.account_name
            ? res.data.data.account_name
            : "",
        });
      }
    });
  };

  const isValidForm = () => {
    return (
      formdata.bank_name &&
      formdata.branch_name &&
      formdata.account_number &&
      formdata.bank_code &&
      formdata.account_name
    );
  };

  useEffect(() => {
    id && onEdit();
  }, [id]);
  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <h5>{id ? "Edit" : "Add"} Banks</h5>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <Link to={"/admin/banks"} className="btn btn-secondary">
                  <i className="fa fa-backward me-2" aria-hidden="true"></i>Back
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={(event) => event.preventDefault()}>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Account Name*</label>
                  <input
                    type="text"
                    name="account_name"
                    value={formdata.account_name}
                    className="form-control"
                    id="account_name"
                    onChange={(event) => {
                      setFormData({
                        ...formdata,
                        account_name: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Bank name*</label>
                  <input
                    type="text"
                    name="bank_name"
                    value={formdata.bank_name}
                    className="form-control"
                    id="bank_name"
                    onChange={(event) => {
                      setFormData({
                        ...formdata,
                        bank_name: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Branch Name*</label>
                  <input
                    type="text"
                    name="branch_name"
                    value={formdata.branch_name}
                    className="form-control"
                    id="branch_name"
                    onChange={(event) => {
                      setFormData({
                        ...formdata,
                        branch_name: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Account Number*</label>
                  <input
                    type="text"
                    name="account_number"
                    value={formdata.account_number}
                    className="form-control"
                    id="account_number"
                    onChange={(event) => {
                      setFormData({
                        ...formdata,
                        account_number: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Bank Code*</label>
                  <input
                    type="text"
                    name="bank_code"
                    value={formdata.bank_code}
                    className="form-control"
                    id="bank_code"
                    onChange={(event) => {
                      setFormData({
                        ...formdata,
                        bank_code: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label className="d-block">Active</label>
                  <input
                    type="checkbox"
                    name="bank_code"
                    style={{ width: 20, height: 20 }}
                    checked={formdata.is_active}
                    onChange={(event) => {
                      setFormData({
                        ...formdata,
                        is_active: event.target.checked,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group text-end">
                <button
                  type="submit"
                  onClick={saveCategory}
                  disabled={!isValidForm()}
                  className="btn btn-primary"
                >
                  Save Banks
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddBanks;
