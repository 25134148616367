import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { API_URL } from "../../shared/constant";
import axios from "axios";
import { toast } from "react-toastify";

const AddTransport = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [formdata, setFormData] = useState({
      id: null,
      name: "",
      gst_no: "",
    });
  
    const saveGrade = () => {
      if (formdata.name) {
        const pUrl = id ? API_URL.TRANSPORT_UPDATE : API_URL.TRANSPORT_SAVE;
        axios.post(pUrl, formdata).then((res) => {
          if (res.data.status === 1) {
            if (id) {
              toast.success("Updated", {
                autoClose: 3000,
                position: "top-center",
              });
              setTimeout(() => {
                navigate("/admin/transport", { replace: true });
              }, 100);
            } else {
              resetForm();
              toast.success("Saved", { autoClose: 3000, position: "top-center" });
            }
          }
        });
      }
    };
  
    const resetForm = () => {
        setFormData({
        id: null,
        name: "",
        gst_no: "",
      });
    };
  
    const onEdit = () => {
      axios.post(API_URL.TRANSPORT_GET, { id: id }).then((res) => {
        if (res.data.status === 1) {
            setFormData({
            id: res.data.data.id,
            name: res.data.data.name ? res.data.data.name : "",
            gst_no: res.data.data.gst_no ? res.data.data.gst_no : "",
          });
        }
      });
    };
  
    const isValidForm = () => {
      return formdata.name;
    };
  
    useEffect(() => {
      id && onEdit();
    }, [id]);
  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <h5>{id ? "Edit" : "Add"} Transport</h5>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <Link to={"/admin/transport"} className="btn btn-secondary">
                  <i className="fa fa-backward me-2" aria-hidden="true"></i>Back
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={(event) => event.preventDefault()}>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Name*</label>
                  <input
                    type="text"
                    name="name"
                    value={formdata.name}
                    className="form-control"
                    id="name"
                    onChange={(event) => {
                        setFormData({ ...formdata, name: event.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>GST</label>
                  <input
                    type="text"
                    name="gst_no"
                    value={formdata.gst_no}
                    className="form-control"
                    id="gst_no"
                    onChange={(event) => {
                        setFormData({ ...formdata, gst_no: event.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group text-end">
                <button
                  type="submit"
                  onClick={saveGrade}
                  disabled={!isValidForm()}
                  className="btn btn-primary"
                >
                  Save Transport
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddTransport;
