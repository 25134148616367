import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../shared/constant";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";

const Add = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [categoryDrp, setCategoryDrpData] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    unit: "",
    hsn_code: "",
    size: "",
    category_id: "0",
    gst: "",
  });

  const getCategoryDrpData = () => {
    axios.post(API_URL.CATEGORY_LIST, {}).then((res) => {
      setCategoryDrpData(res.data.data);
      let cd = res.data.data.map((caw)=>{
        return{
          label:caw.name,
          value:caw.id,
        }
      });
      setCategoryDrpData(cd)
    });
  };

  const resetForm = () => {
    setFormData({
      name: "",
      unit: "",
      category_id: "0",
      hsn_code: "",
      size: "",
      gst: "",
    });
  };

  const saveProduct = () => {
    if (
      formData.name &&
      formData.unit &&
      formData.hsn_code &&
      formData.size &&
      // formData.category_id &&
      formData.gst
    ) {
      const pUrl = id ? API_URL.PRODUCT_UPDATE : API_URL.PRODUCT_SAVE;
      axios.post(pUrl, formData).then((res) => {
        if (res.data.status === 1) {
          if (id) {
            toast.success("Updated", {
              autoClose: 3000,
              position: "top-center",
            });
            setTimeout(() => {
              navigate("/admin/product", { replace: true });
            }, 100);
          } else {
            resetForm();
            toast.success("Saved", { autoClose: 3000, position: "top-center" });
          }
        }
      });
    }
  };

  const onEdit = () => {
    axios.post(API_URL.PRODUCT_GET, { id: id }).then((res) => {
      if (res.data.status === 1) {
        setFormData({
          id: res.data.data.id,
          name: res.data.data.name ? res.data.data.name : "",
          unit: res.data.data.unit ? res.data.data.unit : "",
          category_id: res.data.data.category_id + "",
          hsn_code: res.data.data.hsn_code + "",
          size: res.data.data.size + "",
          gst: res.data.data.gst ? res.data.data.gst : "",
        });
      }
    });
  };

  const isValidForm = () => {
    return (
      formData.name &&
      formData.unit &&
      // formData.size &&
      formData.category_id &&
      formData.gst
    );
  };

  useEffect(() => {
    getCategoryDrpData();
  }, []);

  useEffect(() => {
    id && onEdit();
  }, [id]);

  const options = [
    { value: "Box", label: "Box" },
    { value: "Pcs", label: "Pcs" },
    { value: "Nos", label: "Nos" },
    { value: "Bag", label: "Bag" },
    { value: "Kg", label: "Kg" },
    { value: "Pkt", label: "Pkt" },
    { value: "Bottle", label: "Bottle" },
  ];

  const GstOptions = [
    { value: "5", label: "5%" },
    { value: "12", label: "12%" },
    { value: "18", label: "18%" },
    { value: "28", label: "28%" },
  ];

  const styles = {
    menu: (baseStyles, state) => ({
      ...baseStyles,
      marginTop: 2,
      zIndex: 999,
    }),
  };

  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <h5>{id ? "Edit" : "Add"} Product</h5>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <Link to={"/admin/product"} className="btn btn-secondary">
                  <i className="fa fa-backward me-2" aria-hidden="true"></i>Back
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={(event) => event.preventDefault()}>
            <div className="row">
              {/* <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Category">Category</label>
                  <Select
                    options={categoryDrp}
                    value={categoryDrp.find(
                      (r) => r.value == formData.category_id
                    )}
                    styles={styles}
                    onChange={(e) => setFormData({ ...formData, category_id: e.value })}
                  />
                </div>
              </div> */}
              <div className="col-md-3">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    maxLength={30}
                    value={formData.name}
                    className="form-control"
                    id="name"
                    onChange={(event) => {
                      setFormData({ ...formData, name: event.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>HSN Code</label>
                  <input
                    type="text"
                    name="hsn_code"
                    value={formData.hsn_code}
                    className="form-control"
                    id="hsn_code"
                    onChange={(event) => {
                      setFormData({ ...formData, hsn_code: event.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label>Size</label>
                  <input
                    type="text"
                    name="size"
                    value={formData.size}
                    className="form-control"
                    id="size"
                    onChange={(event) => {
                      setFormData({ ...formData, size: event.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label>Unit</label>
                  <Select
                    options={options}
                    styles={styles}
                    value={options.find((r) => r.value == formData.unit)}
                    onChange={(e) => setFormData({ ...formData, unit: e.value })}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label>Gst</label>
                  <Select
                    options={GstOptions}
                    value={GstOptions.find((r) => r.value == formData.gst)}
                    styles={styles}
                    onChange={(e) => setFormData({ ...formData, gst: e.value })}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group text-end">
                  <button
                    type="submit"
                    onClick={saveProduct}
                    disabled={!isValidForm()}
                    className="btn btn-primary"
                  >
                    Save Product
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Add;
