import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { Oval } from "react-loader-spinner";
import { API_URL } from "../../shared/constant";
import axios from "axios";
import { toast } from "react-toastify";
import moment from 'moment';

const DebitNotes = () => {
  const [creditnotes, setCreditnotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const getCreditnotes = () => {
    axios.post(API_URL.PURCHASE_RETURN_LIST, {}).then(
      (res) => {
        res.data.data.map(item => item.date = moment(item.date).format('DD/MM/YYYY'));
        setCreditnotes(res.data.data);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        toast.error("Something went wrong..", {
          autoClose: 3000,
          position: "top-center",
        });
      }
    );
  };
  useEffect(() => {
    getCreditnotes();
  }, []);
  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <h5>Purchase Return ({creditnotes.length})</h5>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <Link to={"/admin/purchase-return/add"} className="btn btn-primary">
                  <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>
                  Add Purchase Return
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card-table">
            <div className="card-body">
              {loading ? (
                <Oval
                  height={60}
                  width={60}
                  color="#7539ff"
                  wrapperStyle={{ justifyContent: "center" }}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#7539ff"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                <div className="table-responsive">
                  <table className="table table-center table-hover datatable">
                    <thead className="thead-light">
                      <tr>
                        <th>#</th>
                        <th>CR No.</th>
                        <th>Date.</th>
                        <th>Party Name</th>
                        <th>Total Amount</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {creditnotes.map((row, key) => {
                        return (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>
                              <h2 className="table-avatar">{row.cr_no}</h2>
                            </td>
                            <td>
                              <h2 className="table-avatar">{row.date}</h2>
                            </td>
                            <td>
                              <h2 className="table-avatar">{row.party.name}</h2>
                            </td>
                            <td>
                              <h2 className="table-avatar">{row.total_amount}</h2>
                            </td>
                            <td>
                              <Link
                                to={"/admin/purchase-return/edit/" + row.id}
                                className="btn btn-sm btn-secondary me-2"
                              >
                                <i className="fa fa-edit me-1"></i> Edit
                              </Link>
                              <button

                                className="btn btn-sm btn-danger me-2"
                              >
                                <i className="fa fa-trash me-1"></i> Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DebitNotes;
