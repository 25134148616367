const BASE_URL = 'https://api.gnamcera.com/api/';
// const BASE_URL = 'https://api.hitnish.com/api/';

export const API_URL = {

    CATEGORY_LIST: `${BASE_URL}category`,
    CATEGORY_GET: `${BASE_URL}category/show`,
    CATEGORY_SAVE: `${BASE_URL}category/store`,
    CATEGORY_UPDATE: `${BASE_URL}category/update`,
    CATEGORY_DELETE: `${BASE_URL}category/delete`,

    GRADE_LIST: `${BASE_URL}grade`,
    GRADE_GET: `${BASE_URL}grade/show`,
    GRADE_SAVE: `${BASE_URL}grade/store`,
    GRADE_UPDATE: `${BASE_URL}grade/update`,
    GRADE_DELETE: `${BASE_URL}grade/delete`,

    TRANSPORT_LIST: `${BASE_URL}transport`,
    TRANSPORT_GET: `${BASE_URL}transport/show`,
    TRANSPORT_SAVE: `${BASE_URL}transport/store`,
    TRANSPORT_UPDATE: `${BASE_URL}transport/update`,
    TRANSPORT_DELETE: `${BASE_URL}transport/delete`,

    PARTY_LIST: `${BASE_URL}party`,
    PARTY_GET: `${BASE_URL}party/show`,
    PARTY_SAVE: `${BASE_URL}party/store`,
    PARTY_UPDATE: `${BASE_URL}party/update`,
    PARTY_DELETE: `${BASE_URL}party/delete`,

    PRODUCT_LIST: `${BASE_URL}product`,
    PRODUCT_GET: `${BASE_URL}product/show`,
    PRODUCT_SAVE: `${BASE_URL}product/store`,
    PRODUCT_UPDATE: `${BASE_URL}product/update`,
    PRODUCT_DELETE: `${BASE_URL}product/delete`,

    COUNTRY_LIST: `${BASE_URL}country`,
    COUNTRY_GET: `${BASE_URL}country/show`,
    COUNTRY_SAVE: `${BASE_URL}country/store`,
    COUNTRY_UPDATE: `${BASE_URL}country/update`,
    COUNTRY_DELETE: `${BASE_URL}country/delete`,

    BANKS_LIST: `${BASE_URL}banks`,
    BANKS_GET: `${BASE_URL}banks/show`,
    BANKS_SAVE: `${BASE_URL}banks/store`,
    BANKS_UPDATE: `${BASE_URL}banks/update`,
    BANKS_DELETE: `${BASE_URL}banks/delete`,

    SALES_RETURN_LIST:`${BASE_URL}sales-return`,
    SALES_RETURN_SAVE:`${BASE_URL}sales-return/store`,
    SALES_RETURN_SHOW:`${BASE_URL}sales-return/show`,
    SALES_RETURN_DELETE:`${BASE_URL}sales-return/delete`,

    PURCHASE_RETURN_LIST:`${BASE_URL}purchase-return`,
    PURCHASE_RETURN_SAVE:`${BASE_URL}purchase-return/store`,
    PURCHASE_RETURN_SHOW:`${BASE_URL}purchase-return/show`,
    PURCHASE_RETURN_DELETE:`${BASE_URL}purchase-return/delete`,
    
    SETTING_SAVE: `${BASE_URL}setting/store`,
    SETTING_GET: `${BASE_URL}setting/show`,
    SETTING_PASSWORD: `${BASE_URL}setting/password`,
    LOGIN_URL: `${BASE_URL}login`,
    STATES_LIST: `${BASE_URL}states`,
    LOGIN_USER: `${BASE_URL}me`,

    INVOICE_LIST: `${BASE_URL}sales`,
    INVOICE_GET: `${BASE_URL}sales/show`,
    INVOICE_SAVE: `${BASE_URL}sales/store`,
    INVOICE_UPDATE: `${BASE_URL}sales/update`,
    INVOICE_DELETE: `${BASE_URL}sales/delete`,

    PURCHASE_LIST: `${BASE_URL}purchase`,
    PURCHASE_GET: `${BASE_URL}purchase/show`,
    PURCHASE_SAVE: `${BASE_URL}purchase/store`,
    PURCHASE_UPDATE: `${BASE_URL}purchase/update`,
    PURCHASE_DELETE: `${BASE_URL}purchase/delete`,
    
    PAYMENT_LIST: `${BASE_URL}payments`,
    PAYMENT_GET: `${BASE_URL}payments/show`,
    PAYMENT_SAVE: `${BASE_URL}payments/store`,
    PAYMENT_UPDATE: `${BASE_URL}payments/update`,
    PAYMENT_DELETE: `${BASE_URL}payments/delete`,

    LEDGER_LIST: `${BASE_URL}ledgers`,
    OUTSTANDING: `${BASE_URL}ledgers/outstanding`,
}

