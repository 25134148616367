import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { API_URL } from "../../shared/constant";
import axios from "axios";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import Swal from "sweetalert2";
import moment from 'moment';
import ViewPurchase from "./view";

const Purchase = () => {
  const [partyData, setPartyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const getParty = () => {
    axios.post(API_URL.PURCHASE_LIST, {}).then(
      (res) => {
        if (res.data.status === 1) {
          res.data.data.data.map(item => item.date = moment(item.date).format('DD/MM/YYYY'));
          setPartyData(res.data.data.data);
        }
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        toast.error("Something went wrong..", {
          autoClose: 3000,
          position: "top-center",
        });
      }
    );
  };

  const viewPurchase = (row) => {
    setShow(true);
  }

  const deleteParty = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Invoice No - " + row.invoice_no,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios.post(API_URL.PURCHASE_DELETE, { id: row.id }).then(
          (res) => {
            if (res.data.status === 1) {
              let newData = partyData.filter((r) => r.id != row.id);
              setPartyData(newData);
            }
            toast.success(row.name + " Delete Successfully", {
              autoClose: 3000,
              position: "top-center",
            });
            setLoading(false);
          },
          (error) => {
            setLoading(false);
            toast.error("Something went wrong..", {
              autoClose: 3000,
              position: "top-center",
            });
          }
        );
      }
    });
  };

  useEffect(() => {
    getParty();
  }, []);
  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <h5>Purchase ({partyData.length})</h5>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <Link to={"/admin/purchases/add"} className="btn btn-primary">
                  <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>
                  Add Purchase
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card-table">
            <div className="card-body">
              {loading ? (
                <Oval
                  height={60}
                  width={60}
                  color="#7539ff"
                  wrapperStyle={{ justifyContent: "center" }}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#7539ff"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                <div className="table-responsive">
                  <table className="table table-center table-hover datatable">
                    <thead className="thead-light">
                      <tr>
                        <th>Invoice No</th>
                        <th>Name</th>
                        <th>Invoice Date</th>
                        <th>Amount</th>
                        <th>Qty</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {partyData.map((row, key) => {
                        return (
                          <tr key={key}>
                            <td>{row.invoice_no}</td>
                            <td>
                              {row.party.name}
                            </td>
                            <td>
                              {row.date}
                            </td>
                            <td>{row.total_amount}</td>
                            <td>{row.total_qty}</td>
                            <td>
                              <button type="button" onClick={() => viewPurchase(row)} className="btn btn-sm btn-success me-2">
                                View
                              </button>
                              <Link
                                to={"/admin/purchases/edit/" + row.id}
                                className="btn btn-sm btn-secondary me-2"
                              >
                                <i className="fa fa-edit me-1"></i> Edit
                              </Link>
                              <button
                                onClick={() => deleteParty(row)}
                                className="btn btn-sm btn-danger me-2"
                              >
                                <i className="fa fa-trash me-1"></i> Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        size={'xl'}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <button onClick={handleClose} type="button" className="btn btn-sm btn-danger float-end"> <i className="fa fa-times"></i></button>
          <ViewPurchase id={1}></ViewPurchase>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Purchase;
