import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../shared/constant";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import DatePicker from "react-date-picker";
import moment from 'moment';

const AddDebitNote = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const selectInputRef = useRef();
    const [invoiceDate, setInvoiceDate] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [partyData, setPartyData] = useState([]);
    const [products, setProducts] = useState([]);
    const [items, setItems] = useState([]);
    const [productData, setProductData] = useState({
        name: "",
        product_id: "",
        tax: "",
        qty: "",
        price: "",
        amount: "",
    });
    const [formData, setFormData] = useState({
        "party_id": "",
        "invoice_no": "",
        "round_off": "0",
        "sub_total": "0",
        "total_tax": "0",
        "total_amount": "0",
        "date": "",
        "items": []
    });
    const styles = {
        menu: (baseStyles, state) => ({
            ...baseStyles,
            marginTop: 2,
            zIndex: 999,
        }),
    };

    const getParty = () => {
        axios.post(API_URL.PARTY_LIST, { is_seller: [0] }).then((res) => {
            if (res.data && res.data.data && res.data.data.length > 0) {
                let pt = res.data.data.map((raw) => {
                    return {
                        address_one: raw.address_one,
                        address_two: raw.address_two,
                        city: raw.city,
                        gst_no: raw.gst_no,
                        state_name: raw.state_name,
                        name: raw.name,
                        label: raw.name,
                        value: raw.id,
                    };
                });
                setPartyData(pt);
            }
        });
    }

    const getEdit = () => {
        axios.post(API_URL.PURCHASE_RETURN_SHOW, {id: id}).then((res) => {
            if (res.data.status == 1) {
                setFormData({
                    "party_id": (res.data.data.party_id) ? res.data.data.party_id : "",
                    "invoice_no": (res.data.data.invoice_no) ? res.data.data.invoice_no : "",
                    "round_off": (res.data.data.round_off) ? res.data.data.round_off : "0",
                    "sub_total": res.data.data.sub_total,
                    "total_tax": res.data.data.total_tax,
                    "total_amount": res.data.data.total_amount,
                    "date": (res.data.data.date) ? res.data.data.date : "",
                    "remarks": (res.data.data.remarks) ? res.data.data.remarks : "",
                    "items": [],
                });
                if (res.data.data.date) {
                    setInvoiceDate(new Date(res.data.data.date));
                }
                let rows = res.data.data.items.map((row) => {
                    return {
                        name: row.product.name,
                        tax: row.product.gst,
                        qty: row.qty,
                        price: row.price,
                        amount: row.amount,
                        product_id: row.product_id,
                        grade_id: row.grade_id,
                        sub_product_id: row.sub_product_id
                    }
                });
                setItems(rows);
            } else {
                toast.error(res.data.data, { autoClose: 3000, position: "top-center" });
            }
        });
    }
    
    const getProducts = () => {
        axios.post(API_URL.PRODUCT_LIST, {}).then((res) => {
            if (res.data && res.data.data && res.data.data.length > 0) {
                res.data.data.map((raw) => {
                    raw.label = raw.category_name + ' - ' + raw.name;
                    raw.value = raw.id;
                });
                setProducts(res.data.data);
            }
        });
    }

    const setProductValue = (e) => {
        if (e) {
            setProductData({
                ...productData,
                name: e ? e.label: "",
                product_id: e ? e.value: "",
                tax: e ? e.gst: "",
            });               
        }
    }

    const addRow = () => {
        if (productData.product_id && productData.qty && productData.price && productData.amount) {
            let cloneItems = [...items];
            cloneItems.push(productData);
            setItems(cloneItems);
            setProductData({
                name: "",
                product_id: "",
                tax: "",
                qty: "",
                price: "",
                amount: "",
            });
            selectInputRef.current.clearValue();
        }
    }
    const updateValue = (name, amt, value, k) => {
        const copyData = [...items]
        if (copyData[k]) {
            copyData[k][name] = value;
            if (name === 'qty')
                copyData[k][amt] = value * copyData[k]['price']
            if (name === 'price')
                copyData[k][amt] = value * copyData[k]['qty']

        }
        setItems(copyData);
    }

    const deleteRow = (index) => {
        const copyData = [...items]
        let newData = copyData.filter((e, key) => {
            return key != index;
        });
        setItems(newData)
    }

    const calculations = () => {
        let sub_total = 0;
        let total_qty = 0;
        let total_tax = 0;
        items.forEach((row) => {
            sub_total += parseFloat(row.amount);
            total_qty += parseFloat(row.qty);
            total_tax += (parseFloat(row.amount) * parseFloat(row.tax)) / 100;
        });
        let insurance_amount = 0;
        if (formData.insurance) {
            insurance_amount = (sub_total * formData.insurance) / 100;
            insurance_amount = insurance_amount.toFixed(2);
        }
        sub_total = sub_total.toFixed(2);
        let total_amount = parseFloat(sub_total) + parseFloat(insurance_amount);
        if (formData.round_off) {
            total_amount = parseFloat(total_amount) + parseFloat(formData.round_off);
        }
        if (total_tax) {
            total_tax = total_tax.toFixed(2);
            total_amount = parseFloat(total_amount) + parseFloat(total_tax);
        }
        total_amount = total_amount.toFixed(2);
        setFormData({...formData,
            sub_total: sub_total,
            insurance_amount: insurance_amount,
            total_qty: total_qty,
            total_tax: total_tax,
            total_amount: total_amount,
        });
    }

    const isValidForm = () => {
        return (
          formData.party_id &&
          formData.invoice_no &&
          formData.sub_total &&
          formData.total_amount &&
          invoiceDate &&
          items.length
        );
    };
    
    const savePurchase = () => {
        let payload = {...formData};
        payload.items = [...items];
        payload.date = moment(invoiceDate).format('YYYY-MM-DD');
        const pUrl = API_URL.PURCHASE_RETURN_SAVE;
        if (id) {
            payload.id = id;
        }
        setIsLoading(true);
        axios.post(pUrl, payload).then((res) => {
            if (res.data.status === 1) {
                if (id) {
                    toast.success("Updated", {
                        autoClose: 3000,
                        position: "top-center",
                    });
                } else {
                    toast.success("Saved", { autoClose: 3000, position: "top-center" });
                }
                setTimeout(() => {
                    navigate("/admin/purchase-return", { replace: true });
                }, 100);
            } else {
                toast.error(res.data.data, { autoClose: 3000, position: "top-center" });
            }
            setIsLoading(false);
        }, (error) => {
            setIsLoading(false);
        });
    } 

    useEffect(() => {
        calculations();
    }, [items, formData.insurance, formData.round_off])

    useEffect(() => {
        getParty();
        getProducts();
    }, [])

    useEffect(() => {
        if (id) {
            getEdit()
        }
    }, [id])
    return (
        <>
            <div className="page-header">
                <div className="content-page-header">
                    <h5>{id ? "Edit" : "Add"} Purchase Return</h5>
                    <div className="list-btn">
                        <ul className="filter-list">
                            <li>
                                <Link to={"/admin/purchase-return"} className="btn btn-secondary">
                                    <i className="fa fa-backward me-2" aria-hidden="true"></i>Back
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <form onSubmit={(event) => event.preventDefault()}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mb-3 title-header">Party Details</h4>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="name">Party</label>
                                            <Select
                                                options={partyData}
                                                value={partyData.find((r) => r.value == formData.party_id)}
                                                styles={styles}
                                                onChange={(e) => {
                                                    setFormData({ ...formData, party_id: e.value });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mb-3 title-header">Invoice Details</h4>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="invoiceno">Invoice No</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.invoice_no}
                                                onChange={(e) => setFormData({ ...formData, invoice_no: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">Date</label>
                                            <DatePicker
                                                format={'dd/MM/y'}
                                                className="form-control"
                                                onChange={setInvoiceDate}
                                                value={invoiceDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mb-3 title-header">Product Details</h4>
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Product</label>
                                            <Select
                                                ref={selectInputRef}
                                                options={products}
                                                styles={styles}
                                                onChange={(e) => { setProductValue(e) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Qty</label>
                                            <input
                                                type="number"
                                                onChange={(e) => {
                                                    setProductData({
                                                        ...productData,
                                                        qty: e.target.value,
                                                        amount: (e.target.value * productData.price) ? e.target.value * productData.price : 0,
                                                    });
                                                }}
                                                value={productData.qty}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Price</label>
                                            <input
                                                type="number"
                                                onChange={(e) => {
                                                    setProductData({
                                                        ...productData,
                                                        price: e.target.value,
                                                        amount: (e.target.value * productData.qty) ? e.target.value * productData.qty : 0,
                                                    });
                                                }}
                                                value={productData.price}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Amount</label>
                                            <input
                                                type="text"
                                                value={productData.amount}
                                                className="form-control"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className="form-group">
                                            <label>&nbsp;</label>
                                            <div>
                                                <button onClick={() => addRow()} className="btn btn-primary btn-outline"><span className="fa fa-plus f-20"></span></button>
                                                {/* <AiFillPlusSquare
                                                    color={"green"}
                                                    onClick={() => addRow()}
                                                    className="mt-2"
                                                    size={40}
                                                /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    {items.length > 0 &&
                        <table className='table table-hover'>
                            <thead className='thead'>
                                <tr>
                                    <th>#</th>
                                    <th style={{ width: '45%' }}>Product</th>
                                    <th>Qty</th>
                                    <th>Price</th>
                                    <th>GST</th>
                                    <th>Amount</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    items.map((e, k) => {
                                        return <tr key={k}>
                                            <td>{k + 1}</td>
                                            <td style={{ width: '45%' }}>{e.name}</td>
                                            <td><input type="number" onChange={(event) => { updateValue('qty', 'amount', event.target.value, k) }} className="form-control form-control-sm" value={e.qty} /></td>
                                            <td><input type="number" onChange={(event) => { updateValue('price', 'amount', event.target.value, k) }} className="form-control form-control-sm" value={e.price} /></td>
                                            <td><input type="text" className="form-control form-control-sm" value={e.tax +'%'} disabled /></td>
                                            <td><input type="text" className="form-control form-control-sm" value={e.amount} disabled /></td>
                                            <td style={{ cursor: "pointer" }}>
                                                <button onClick={() => { deleteRow(k) }} className="btn btn-danger btn-outline"><span className="fa fa-close"></span></button>
                                                {/* <AiFillCloseSquare color={'red'} onClick={() => { deleteRow(k) }} size={35} /> */}
                                            </td>
                                        </tr>
                                    })
                                }
                                <tr className="border-top">
                                    <td className="border-end" colSpan={4} align={'right'}></td>
                                    <td className="border-end" align={'right'}><strong>Sub Total</strong></td>
                                    <td colSpan={1} align={'right'}><strong>{formData.sub_total}</strong></td>
                                    <td></td>
                                </tr>
                                { (formData.insurance) ? <tr className="border-top">
                                    <td className="border-end" colSpan={4} align={'right'}></td>
                                    <td className="border-end" align={'right'}><strong>Insurance ({formData.insurance}%)</strong></td>
                                    <td colSpan={1} align={'right'}><strong>{formData.insurance_amount}</strong></td>
                                    <td></td>
                                </tr>: null}
                                <tr className="border-top">
                                    <td className="border-end" colSpan={4} align={'right'}></td>
                                    <td className="border-end" align={'right'}><strong>GST</strong></td>
                                    <td colSpan={1} align={'right'}><strong>{formData.total_tax}</strong></td>
                                    <td></td>
                                </tr>
                                <tr className="border-top">
                                    <td className="border-end" rowSpan={2} colSpan={4}>
                                        <label>Remarks</label>
                                        <input type={'text'} maxLength={255} value={formData.remarks} placeholder="remarks" className='form-control' onChange={(e) => setFormData({...formData, remarks: e.target.value})} />
                                    </td>
                                    <td className="border-end" align={'right'}><strong>Round Off</strong></td>
                                    <td colSpan={1} align={'right'}> <input type={'number'} value={formData.round_off} className='form-control form-control-sm text-end' onChange={(e) => setFormData({...formData, round_off: e.target.value})} /> </td>
                                    <td></td>
                                </tr>
                                <tr className="border-top">
                                    <td className="border-end" align={'right'}><strong>Final Amount</strong></td>
                                    <td colSpan={1} align={'right'}><strong>{formData.total_amount}</strong></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    }
                </div>
            </form>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group text-end">
                    <button
                        type="button"
                        onClick={savePurchase}
                        disabled={!isValidForm() || isLoading}
                        className="btn btn-primary" >
                            { isLoading ? <span className="spinner-border spinner-border-sm me-2" role="status"></span> : null}
                        Save Purchase
                    </button>
                </div>
            </div>
        </>
    )
}

export default AddDebitNote;