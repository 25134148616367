import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { API_URL } from "../../shared/constant";
import axios from "axios";
import { toast } from "react-toastify";

const AddParty = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [statesData, setStatesData] = useState([]);
  const [formValue, setFormValue] = useState({
    id: null,
    is_seller: "",
    name: "",
    city: "",
    state_id: "",
    address_one: "",
    address_two: "",
    pincode: "",
    gst_no: "",
    pan_no: "",
  });

  const options = [
    { value: "1", label: "For Sales" },
    { value: "0", label: "For Purchase" },
  ];

  const styles = {
    menu: (baseStyles, state) => ({
      ...baseStyles,
      marginTop: 2,
    }),
  };

  const resetForm = () => {
    setFormValue({
      id: null,
      is_seller: "",
      name: "",
      city: "",
      state_id: "",
      address_one: "",
      address_two: "",
      pincode: "",
      gst_no: "",
      pan_no: "",
    });
  };

  const getStates = () => {
    axios.post(API_URL.STATES_LIST, {}).then((res) => {
      if (res.data.status === 1) {
        let stsData = res.data.data.map((row) => {
          return { label: row.name, value: row.id };
        });
        setStatesData(stsData);
      }
    });
  };

  const onEdit = () => {
    axios.post(API_URL.PARTY_GET, { id: id }).then((res) => {
      if (res.data.status === 1) {
        setFormValue({
          id: res.data.data.id,
          is_seller: res.data.data.is_seller ? res.data.data.is_seller : "",
          name: res.data.data.name ? res.data.data.name : "",
          city: res.data.data.city ? res.data.data.city : "",
          state_id: res.data.data.state_id ? res.data.data.state_id : "",
          address_one: res.data.data.address_one
            ? res.data.data.address_one
            : "",
          address_two: res.data.data.address_two
            ? res.data.data.address_two
            : "",
          pincode: res.data.data.pincode ? res.data.data.pincode : "",
          gst_no: res.data.data.gst_no ? res.data.data.gst_no : "",
          pan_no: res.data.data.pan_no ? res.data.data.pan_no : "",
        });
      }
    });
  };

  const saveParty = () => {
    if (
      formValue.name &&
      formValue.is_seller &&
      formValue.address_one &&
      formValue.city &&
      formValue.pincode &&
      formValue.state_id
    ) {
      const pUrl = id ? API_URL.PARTY_UPDATE : API_URL.PARTY_SAVE;
      axios.post(pUrl, formValue).then((res) => {
        if (res.data.status === 1) {
          if (id) {
            toast.success("Updated", {
              autoClose: 3000,
              position: "top-center",
            });
            setTimeout(() => {
              navigate("/admin/party", { replace: true });
            }, 100);
          } else {
            resetForm();
            toast.success("Saved", { autoClose: 3000, position: "top-center" });
          }
        }
      });
    }
  };

  const isValidForm = () => {
    return (
      formValue.name &&
      formValue.is_seller &&
      formValue.address_one &&
      formValue.city &&
      formValue.pincode &&
      formValue.state_id
    );
  };

  const setFormData = (name, value) => {
    setFormValue({ ...formValue, [name]: value });
  };

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    id && onEdit();
  }, [id]);

  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <h5>{id ? "Edit" : "Add"} Party</h5>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <Link to={"/admin/party"} className="btn btn-secondary">
                  <i className="fa fa-backward me-2" aria-hidden="true"></i>Back
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={(event) => event.preventDefault()}>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Party For</label>
                  <Select
                    options={options}
                    value={options.find((r) => r.value == formValue.is_seller)}
                    styles={styles}
                    onChange={(e) => setFormData("is_seller", e.value)}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    value={formValue.name}
                    onChange={(e) => setFormData("name", e.target.value)}
                    className="form-control"
                    placeholder="Enter Name"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Address Line 1</label>
                  <input
                    type="text"
                    value={formValue.address_one}
                    onChange={(e) => setFormData("address_one", e.target.value)}
                    className="form-control"
                    placeholder="Address Line 1"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Address Line 2</label>
                  <input
                    type="text"
                    value={formValue.address_two}
                    onChange={(e) => setFormData("address_two", e.target.value)}
                    className="form-control"
                    placeholder="Address Line 2"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>City</label>
                  <input
                    type="text"
                    value={formValue.city}
                    onChange={(e) => setFormData("city", e.target.value)}
                    className="form-control"
                    placeholder="City"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>State</label>
                  <Select
                    options={statesData}
                    value={statesData.find(
                      (r) => r.value == formValue.state_id
                    )}
                    styles={styles}
                    onChange={(e) => setFormData("state_id", e.value)}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Pincode</label>
                  <input
                    type="text"
                    value={formValue.pincode}
                    onChange={(e) => setFormData("pincode", e.target.value)}
                    className="form-control"
                    placeholder="Pincode"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-groupF">
                  <label>GST</label>
                  <input
                    type="text"
                    value={formValue.gst_no}
                    onChange={(e) => setFormData("gst_no", e.target.value)}
                    className="form-control"
                    placeholder="GST"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>PAN</label>
                  <input
                    type="text"
                    value={formValue.pan_no}
                    onChange={(e) => setFormData("pan_no", e.target.value)}
                    className="form-control"
                    placeholder="PAN"
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group text-end">
                  <button
                    type="submit"
                    onClick={saveParty}
                    disabled={!isValidForm()}
                    className="btn btn-primary"
                  >
                    Save Party
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddParty;
