import { React, useState, useEffect } from 'react';
import { API_URL } from '../../shared/constant';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const LoginComponent = () => {

    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    
    const handleSubmit = (event) => {
        event.preventDefault();
        if (email && password) {
            setLoading(true);
            axios.post(API_URL.LOGIN_URL, { email: email, password: password }).then(res => {
                if (res.data.access_token) {
                    sessionStorage.setItem('access_token', res.data.access_token);
                    sessionStorage.setItem('user_name', res.data.user_name);
                    sessionStorage.setItem('logo', res.data.logo);
                    navigate('/admin/dashboard', { replace: true });
                } else {
                    toast.error('Invalid Username or Password', { autoClose: 3000, position: 'top-center' })
                }
                setLoading(false);
            }, error => {
                setLoading(false);
                toast.error('Something went wrong..', { autoClose: 3000, position: 'top-center' })
            })
        } else {
            toast.error('Please valid Username and Password', { autoClose: 3000, position: 'top-center' })
        }
    }

    useEffect(() => {
        const access_token = sessionStorage.getItem('access_token');
        if (access_token) {
            navigate('admin/dashboard', { replace: true });
        }
    }, [])

    return (
        <div className="main-wrapper login-body">
            <div className="login-wrapper">
                <div className="container">
                    <div className="loginbox">
                        <div className="login-right">
                            <div className="login-right-wrap">
                                <h1>Login</h1>
                                <p className="account-subtitle">Access to our dashboard</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label className="form-control-label">Email Address</label>
                                        <input type="email" className="form-control" onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-control-label">Password</label>
                                        <div className="pass-group">
                                            <input type="password" className="form-control pass-input" onChange={(e) => setPassword(e.target.value)} />
                                            <span className="fas fa-eye toggle-password"></span>
                                        </div>
                                    </div>
                                    <button className="btn btn-lg btn-block btn-primary w-100" type="submit">
                                        { isLoading ? <span className="spinner-border spinner-border-sm me-2" role="status"></span> : null}
                                        Login
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
export default LoginComponent;