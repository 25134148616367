import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

const AddInvoice = () => {
    const [partyData, setPartyData] = useState([]);

    const getParty = () => {
        let p = [];
        for (let index = 1; index < 11; index++) {
            p.push({id: index})
        }
        setPartyData(p);
    }

    useEffect(() => {
        getParty();
    }, [])
    return (
        <>
        <div className="page-header">
            <div className="content-page-header">
                <h5>AddInvoice</h5>
                <div className="list-btn">
                    <ul className="filter-list">
                        <li>
                            <button type='button' className="btn btn-primary"><i className="fa fa-plus-circle me-2" aria-hidden="true"></i>Add Party</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="row">
                    <div className="col-sm-12">
                        <div className="card-table">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-center table-hover datatable">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Phone</th>
                                                <th>Status</th>
                                                <th>Created</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { 
                                                partyData.map((row, key) => {
                                                return <tr key={key}>
                                                    <td>{row.id}</td>
                                                    <td>
                                                        <h2 className="table-avatar">
                                                            John Smith
                                                        </h2>
                                                    </td>
                                                    <td>+1 989-438-3131</td>
                                                    <td>Morbi, Gujarat</td>
                                                    <td>19 Dec 2022</td>
                                                    <td>
                                                        <button className="btn btn-sm btn-secondary me-2"><i className="fa fa-plus-circle me-1"></i> Edit</button>
                                                        <button className="btn btn-sm btn-danger me-2"><i className="fa fa-trash me-1"></i> Delete</button>
                                                    </td>
                                                </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default AddInvoice;