import React from 'react';
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();
    const userName = sessionStorage.getItem('user_name');
    const logout = () => {
        sessionStorage.clear();
        navigate("/", { replace: true });
    }
    return (
        <div className="header header-one hidden-print">

            <a className="mobile_btn" id="mobile_btn">
                <i className="fas fa-bars"></i>
            </a>


            <ul className="nav nav-tabs user-menu">

                <li className="nav-item dropdown">
                    <button className='btn btn-primary' onClick={logout}>Log Out</button>
                    {/* <a  className="user-link  nav-link" data-bs-toggle="dropdown">
                        <span className="user-img">
                            <img src="/assets/img/profiles/avatar.png" alt="img" className="profilesidebar" />
                        </span>
                        <span className="user-content">
                            <span className="user-details">Admin</span>
                            <span className="user-name">{userName}</span>
                        </span>
                    </a>
                    <div className="dropdown-menu menu-drop-user">
                        <div className="profilemenu">
                            <div className="subscription-menu">
                                <ul>
                                    <li>
                                        <a className="dropdown-item" href="profile.html">Profile</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="settings.html">Settings</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="subscription-logout">
                                <ul>
                                    <li className="pb-0">
                                        <a className="dropdown-item" href="login.html">Log Out</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                </li>
            </ul>
        </div>
    )
}

export default Header;